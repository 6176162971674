@import "@fontsource/ibm-plex-mono";
@import "@fontsource-variable/montserrat";
@import "@fontsource/poppins";
.landingpage-container {
  overflow-x: hidden;
}
.block {
  height: 85vh;
  width: 100vw;
}
.block1 {
  background-color: var(--darkGreen);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.block3 {
  background-color: var(--darkGreen);
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
  padding-top: 30px;
  /* padding-left: 10vw; */
}
.phone {
  margin: auto;
  /* margin-left: 12vw; */
  margin-top: 50px;
  max-width: 400px;
}
.typedTitle {
  font-size: 4.5em;
  color: var(--white);
  max-width: 50vw;
  width: 50vw;
  margin-top: 25vh;
  /* padding-left: 5vw; */
  font-family: "IBM Plex Mono", monospace;
}
.block2 {
  background-color: var(--mediumBlue);
  padding-bottom: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.introductionContainer {
  padding-top: 300px;
  padding-left: 50px;
}
.introductionPara {
  color: var(--white);
  line-height: 2;
  max-width: 700px;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
}
.typedTitle2 {
  font-size: 3em;
  font-family: "IBM Plex Mono", monospace;
  color: var(--yellow);
}
.phoneblock2 {
  max-width: 350px;
  margin-top: 50px;
}
.title3 {
  margin-top: 50px;
  text-align: center;
}
.b3b1 {
  font-family: "Poppins", sans-serif;
  color: white;
  max-width: 500px;
  margin: auto;
  margin-top: 30px;
  line-height: 1.5;
  text-align: center;
}
.specialtyContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.specImgContainer {
  position: relative;
  text-align: center;
  color: white;
  width: 33%;
  transition: transform 0.5s;
  padding: 60px 0px 30px 0px;
  width: 40vw;
}
.specImgText {
  position: absolute;
  font-family: "Montserrat Variable", sans-serif;

  font-size: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.specImgText:hover {
  cursor: pointer;
}
.specImage {
  max-width: 400px;
  filter: brightness(30%);
  border-radius: 45px 0.1px 45px 0.1px;
  transition: transform 0.5s;
}
.specImgContainer:hover {
  transform: scale(1.1);
  filter: brightness(140%);
  cursor: pointer;
}
@media (max-width: 1300px) {
  .specImgContainer {
    width: 40vw;
  }
}

/*ipad and tablet*/
@media (max-width: 1025px) and (min-width: 600px) {
  .phone {
    /* margin-left: -70px; */
    max-width: 50vw;
    margin-top: 0px;
    filter: brightness(50%);
  }

  .typedTitle {
    position: absolute;
    top: 170px;
    left: 33%;
    font-size: 5vw;
    max-width: 40vw;
  }
  .introductionContainer {
    padding-top: 30vw;
    padding-left: 50px;
    padding-right: 20px;
  }
  .phoneblock2 {
    margin-top: 25vw;
    max-width: 300px;
    margin-right: 20px;
  }
  .specImage {
    max-width: 250px;
  }
  .specImgText {
    font-size: 23px;
  }
}
/*phone*/
@media screen and (max-width: 600px) {
  .specialtyContainer {
    width: 70vw;
    flex-direction: column;
    align-items: center;
    padding-right: 40px;
    padding: 0px;
  }
  .intro-lp {
    margin-top: 40px;
  }
  .specImage {
    max-width: 80vw;
  }
  .specImgText {
    font-size: 23px;
    left: 90%;
  }
  .lp-b2 {
    padding: 0px 0px 100px 0px !important;
    /* margin: 0px !important; */
  }
  .block3 {
    padding-left: 0vw;
  }
  .phone {
    /* margin-left: -70px; */
    margin-top: 0px;
    max-width: 90vw;
    filter: brightness(50%);
  }
  .typedTitle {
    position: absolute;
    top: 150px;
    left: 20%;
    height: 70vh;
    font-size: 8vw;
  }
  .phoneblock2 {
    margin-top: 30vh;
    max-width: 95vw;
    margin-right: 0px;
    overflow-x: hidden;
    margin-left: -20px;
    filter: brightness(40%);
    /* height: 160vh; */
    display: none;
  }

  .introductionContainer {
    /* position: absolute; */
    /* background-image: url("https://res.cloudinary.com/kcb-software-design/image/upload/v1731473922/01_Q3i_Phone_Mockup3_e7g5gr-dark_dri9vb.png"); */
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(https://res.cloudinary.com/kcb-software-design/image/upload/v1713925263/01_Q3i_Phone_Mockup3_e7g5gr.png); */
    background-size: contain;
    background-repeat: no-repeat;
    height: max-content;
    /* width: 90vw; */
    padding-top: 00px;
    /* padding-bottom: 350px; */
    padding-left: 25px;
    padding-right: 15px;

    margin-top: 190px;
    z-index: 1000;
    /* background */
  }
  .lpp2 {
    max-width: 85vw;
    padding-left: 20px;
    padding-bottom: 50px;
  }
  .title2 {
    padding-left: 20px;
  }
  .wwa-lp {
    margin-top: 60px;
    margin-left: 20px;
  }
  .introductionPara {
    line-height: 1.7;
  }
  .typedTitle2 {
    font-size: 37px;
  }
  .b3b1 {
    text-align: left;
    padding-left: 7vw;
    padding-right: 5vw;
  }
  .specialtyContainer {
    align-items: start;
    padding-left: 10vw;
  }
  .specImgContainer {
    align-self: left;
  }
}
