@import "@fontsource/ibm-plex-mono";

.tabletContainer {
  align-items: center;
  position: relative;
  /* display: flex; */
  /* flex-direction: row;
  justify-content: space-around; */
  width: 100vw;
  height: 40vh;
}
.webOfValuesImg {
  width: 50vw;
  align-self: center;
}
.valuesBlock {
  display: flex;
  flex-direction: column;
  width: 100vw;
}
.backgroundImageWWA {
  width: 100vw;
  height: 40vh;
  object-fit: cover;
  filter: brightness(60%);
  overflow-x: hidden !important;
}
.tablet-horizontal {
  width: 40vw;
  margin-top: 25%;
  vertical-align: middle;
  /* filter: brightness(90%); */
  padding: 50px 60px 50px 0px;
}
.tablet-vertical {
  margin-top: 25%;
  display: none;
}
.tablet-title,
.secondaryWWA,
.valuesTitleWWA {
  font-family: "Montserrat Variable", sans-serif;
  font-family: "IBM Plex Mono", monospace;
  color: var(--yellow);
  font-size: 55px;
  /* max-width: 200px; */
}
.valuesTitleWWA {
  text-align: center;
  font-size: 35px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.tablet-title {
  font-size: 58px;
  position: absolute;
  top: 40%;
  font-weight: normal;
  /* max-width: 200px; */
  left: 35%;
}
.secondaryWWA {
  font-size: 35px;
  text-align: left;
  align-items: left;
}
.WWAsecondary-container {
  padding-top: 5vh !important;
}
.valueContainer {
  border: solid;
  color: var(--yellow);
  align-items: center;
  padding: 30px;
  margin-top: 50px;
  display: flex;
  width: 60vw;
  flex-direction: column;
  align-self: center;
}
.integrity {
  width: 500px;
}
.iconsWWA {
  max-height: 100px;
}
.katherine-photo {
  max-width: 450px;
  border-radius: 250px;
  padding-top: 50px;
}
.katherineImageContainer {
  width: 40vw;
}
.founder-container {
  padding-top: 50px;
  padding-left: 20px;
}
.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
}
.lb-bg {
  background-color: var(--darkGreen);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.con-title {
  color: var(--yellow);
  font-family: "IBM Plex Mono", monospace;
  font-size: 35px;
}
.con-sub {
  color: white;
  font-family: "Montserrat Variable", sans-serif;
  padding-top: 25px;
  padding-bottom: 25px;
  max-width: 400px;
  line-height: 2;
}
.contact-con-1 {
  padding-bottom: 50px;
}
.socialicons {
  color: white;
  width: 40px;
  height: 40px;
}
.questions {
  /* color: var(--yellow); */
  color: white;
  font-family: "Montserrat Variable", sans-serif;
}
.submitButton {
  font-family: "IBM Plex Mono", monospace;
  color: var(--yellow);
  background-color: var(--darkGreen);
  border-color: var(--yellow);
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
  font-size: 16px;
}
.submitButton:hover {
  color: var(--darkGreen);
  background-color: var(--yellow);
  cursor: pointer;
  font-weight: bold;
}
.inputs-con {
  border-radius: 5px;
  background-color: var(--grey);
  border-color: var(--grey);
  box-shadow: none !important;
  border-width: 0px;
  height: 30px;
  margin-top: 15px;
  width: 400px;
}
input,
textarea {
  color: white;
}
textarea {
  height: 150px !important;
}
.lightblue {
  background-color: var(--lightBlue);
}
.construction-title {
  font-size: 32px;
}
.construction-text {
  font-size: 20px;
}
.center {
  text-align: center;
}
@media (max-width: 1200px) {
  .blockKatherine {
    flex-direction: column;
    align-items: center;
  }
  .aboutKatherine {
    text-align: center;
  }
  .founderTyped {
    text-align: center;
    margin: auto;
    width: 100vw;
    align-self: center;
    margin-left: 13%;
  }
  .katherineImageContainer {
    width: auto;
  }
  .founder-container {
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media (max-width: 1200px) {
  .tablet-vertical {
    display: block;
    margin-top: 100px;
    width: 50vw;
  }

  .tablet-horizontal {
    display: none;
    filter: brightness(30%);
  }
}
@media (max-width: 1000px) {
  .block2 {
    padding-left: 20px;
  }

  .tablet-title {
    font-size: 40px;

    width: 400px;
    left: 25vw;
  }
  .tabletContainer {
    /* padding-top: 50px;
    padding-bottom: 50px; */
    position: relative;
    text-align: center;
    color: var(--yellow);
    width: 100vw;
  }
}
@media (max-width: 900px) {
  .tablet-vertical {
    display: none;
  }
  .webOfValuesImg {
    width: 90vw;
    padding-right: 20px;
  }
  .introductionPara {
    padding-right: 10px;
  }
}
@media (max-width: 600px) {
  .tablet-title {
    left: 5%;
  }
  .valuesTitleWWA {
    font-size: 40px;
  }
  .integrity {
    font-size: 40px;
  }
  .founderTyped {
    margin-left: 0%;
  }
  .katherine-photo {
    max-width: 80vw;
  }
  .aboutKatherine {
    text-align: left;
  }
  .valueContainer {
    width: 80vw;
  }
  .inputs-con {
    width: 80vw;
  }
}
