@import "@fontsource/poppins";
@import "@fontsource/ibm-plex-mono";
.navbar-container {
  height: 110px;
  background-color: var(--darkGreen);
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.logo {
  width: 25vw;
  margin: 15px;
  max-width: 200px;
  margin-left: 5vw;
}
.menuItem {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}
.hover:hover {
  color: var(--yellow);
}
.dropdown-list {
  position: absolute;
  background-color: var(--darkGreen);
  margin-top: 5px;
  z-index: 100000;
  box-shadow: 0px 15px 20px black;
  /* transform: scaleY(0);
  transition: 1000ms; */
  /* padding: 10px; */
}
/* .dropdown-list:hover {
  transform: scaleY(1);
  transition: 1000ms;
} */
li {
  list-style-type: none;
}
.nav-menu-items {
  width: 100vw;
}

.menu-list {
  display: flex;
  justify-content: space-between;
  width: 40vw;
}
.dropdown-item {
  color: var(--yellow);
  font-family: "IBM Plex Mono", monospace;
  font-size: 16px;
  transition: transform 0.5s;
  padding: 15px 10px 10px 30px;
}

.dropdown-item:hover {
  background-color: var(--yellow);
  color: black;
  transform: scale(1.1);
  font-weight: bold;
}
.dropdown-item:hover a {
  color: black;
}
.logoFooter {
  width: 10vw;
  margin: 15px;
  max-width: 200px;
  margin-left: 5vw;
  margin-top: 60px;
}
.line-navbar {
  background-color: var(--yellow);
  height: 2px;
}
.footer-container {
  background-color: var(--darkGreen);
  padding-bottom: 30px;
}
.lsb1 {
  font-family: "Poppins", sans-serif;
  color: white;
  max-width: 200px;
  margin-left: 5vw;
  margin-top: 30px;
}
.followus {
  font-family: "IBM Plex Mono", monospace;
  color: var(--yellow);
  font-size: 20px;
  font-weight: bold;
  margin-left: 5vw;
}
.instaIcon {
  max-width: 40px;
  margin-top: 20px;
}
/* .menu-list {
  margin-right: 2vw;
} */
.menu-bars {
  margin-left: -110px;
  font-size: 2.2rem;
  background: none;
  align-self: center;
  visibility: hidden;
}
.nav-menu {
  background-color: var(--darkGreen);
  z-index: 1000 !important;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: -200%;
  left: 0;
  /* right: -100%; */
  transition: 1000ms;
  list-style-type: none;
  visibility: hidden;
}
.nav-menu.active {
  /* right: 0; */
  top: 0;

  transition: 750ms;
}
.navbar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: center;
  text-decoration: none;
}
.menu-x {
  align-self: center;
  margin-left: 40%;
}
.sideBarDataContainer {
  text-align: center;
  margin-left: -10%;
  margin-top: 25px;
}
.navItem {
  font-family: "IBM Plex Mono", monospace;
  color: white;
  font-size: 30px;
}
.nav-container {
  padding-bottom: 35px;
}
.contact {
  padding-top: 60px;
}
.navItem-service {
  color: var(--yellow);
  font-size: 21px;
  font-family: "IBM Plex Mono", monospace;
  text-decoration: "none";
}
.nav-service-container {
  margin-top: 25px;
}
@media (max-width: 900px) {
  .navbar-container {
    justify-content: space-around;
  }
  .menu-list {
    visibility: hidden;
  }
  .menu-bars {
    visibility: visible;
  }
  .nav-menu {
    visibility: visible;
  }
}

/*phone*/
@media (max-width: 600px) {
  .logo {
    width: 150px !important;
    margin-left: 0px;
  }
  .logoFooter {
    width: 80px;
  }
}
