:root {
  --darkGreen: #0f2a23;
  --yellow: #e5e550;
  --lightGreen: #8cc63f;
  --darkTurquoise: #40b58a;
  --mediumBlue: #013557;
  --lightBlue: #00a5cd;
  --dirtyGreen: #8ea238;
  --lightTurquoise: #58eda7;
  --white: #ffffff;
  --grey: #323233;
  --lightgrey: #adadb3;
}

/* * {
  font-family: "Poppins", sans-serif;
} */
html,
body {
  background-color: #0f2a23; /* Change to your desired color */
}
