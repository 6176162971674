.termsBody {
  white-space: pre-line;
  /* display: block; */
  /* word-wrap:normal;
	max-width: 90vw;
	height: auto; */
  /* white-space: break-spaces; */
  text-indent: 1em;
  margin-left: 5vw;
  margin-right: 5vw;
}
