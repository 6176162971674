.b1 {
  color: var(--white);
  line-height: 2;
  max-width: 700px;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}
.types-of-design {
  width: 130px;
  transition: transform 0.5s;
}
.types-of-design:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.typed-seo-1 {
  width: 400px;
}
.sticky-container {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-top: 40px;
  padding-bottom: 40px;
  /* flex-direction: column; */
  /* padding-left: 20px;
  padding-right: 20px; */
}
.seotext {
  padding-left: 50px;
}
.web-dev-graphic {
  height: 100%;
  width: 400px;
  object-fit: contain;
  margin: auto;
}
.wwo-seo {
  min-width: 250px;
  padding-bottom: 60px;
  padding-top: 25px;
  text-align: center;
  margin: auto;
}
.wwo-container {
  padding: 30px 50px 50px 50px;
}
.blue {
  background-color: var(--mediumBlue);
}
.green {
  background-color: var(--darkGreen);
}
.ws-subtitle {
  color: var(--yellow);
  font-weight: normal;
  font-size: 30px;
  font-family: "Poppins", sans-serif;
}
.ws-des {
  color: white;
  font-family: "Poppins", sans-serif;
  line-height: 2;
  max-width: 600px;
}
.ws-end {
  color: white;
  font-family: "IBM Plex Mono", monospace;
  line-height: 2;
  max-width: 600px;
  font-size: 20px;
}
.yellow-text {
  color: var(--yellow);
}
.ws-h3 {
  margin-top: 40px;
  color: var(--yellow);
  font-family: "IBM Plex Mono", monospace;
  font-size: 20px;
}
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.margin {
  padding: 70px 30px 70px 30px;
}
.types-images {
  width: 300px;
}
.yellow {
  background-color: var(--yellow);
}
.button-primary {
  cursor: pointer;
  padding: 15px 10px 15px 10px;
  background-color: var(--darkGreen);
  border-radius: 20px;
  border-style: solid;
  border-width: 2px;
  border-color: white;
  color: var(--yellow);
  font-family: "IBM Plex Mono", monospace;
  transition: transform 0.5s;
  font-size: 22px;
  margin-top: 50px;
}
.button-primary:hover {
  color: var(--darkGreen);
  background-color: var(--yellow);
  border-color: var(--darkGreen);
  transform: scale(1.1);
}
.regText {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: var(--darkGreen);
  margin-left: 15px;
}
.downarrow {
  color: black;
  font-size: 32px;
  animation: MoveUpDown 2s linear infinite;
  position: relative;
}
.float {
  animation: MoveUpDown 2s linear infinite;
  position: relative;
}
.rocketman {
  width: 250px !important;
}
.image-con1 {
  background-size: cover;
  padding: 50px 50px 50px 50px;
}
.seotext-para {
  min-width: 250px;
}
.seoSecondPic {
  max-width: 350px;
  margin-top: 50px;
  max-width: 40vw;
  padding: 0px 50px 0px 20px;
}
.ws-8Web {
  max-width: 80vw;
}

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 20px;
  }
}
@media (max-width: 1425px) {
  .seoSecondPic {
    min-width: 500px;
    padding: 0px 50px 0px 0px;
  }
}
@media (max-width: 1130px) {
  .ws-web-of-data {
    width: 30vw;
    height: 25vw;
    margin-top: 50px;
  }
}
@media (max-width: 950px) {
  .bottom-ws {
    flex-direction: column;
  }
  .ws-rocket {
    margin-top: 70px;
  }
  .ws-end-cont {
    padding-left: 10%;
  }
  .ws-web-of-data {
    width: 250px;
    height: 200px;
  }
}
@media (max-width: 765px) and (min-width: 600px) {
  .typed-seo-1 {
    width: 350px;
    height: 100px;
  }
}
@media (max-width: 600px) {
  .block2 {
    flex-direction: column;
    padding-right: 20px;
  }
  .web-dev-graphic {
    /* margin-right: 15px; */
    padding-top: 25px;
    width: 350px;
  }
  .sticky-container {
    width: 100%;
  }
  .rowreverse {
    flex-direction: column;
  }
  .columnreverse {
    flex-direction: column-reverse;
  }
  .seoSecondPic {
    min-width: 90vw;
    /* margin-left: -30px; */
    filter: brightness(100%);
  }
  .typed-seo-1 {
    width: 90vw;
  }
  .rocketman {
    width: 100px !important;
    padding-top: 50px;
  }
  .ws-rocket {
    display: none;
  }
  .wwo-seo {
    width: 90vw;
  }
  .wwo-container {
    padding: 30px 0px 50px 0px;
  }
  .seo-b1 {
    flex-wrap: wrap;
  }
  .seob1-typed {
    font-size: 30px;
  }
  .image-con1 {
    padding: 50px 10px 50px 20px;
  }
  .seotext {
    padding-left: 20px;
  }
  .seotext-para {
    width: 95vw;
  }
  .ws-web-of-data {
    margin: auto;
    margin-top: 40px;
  }
}
